import { ApiCall } from "@Utils/api";
import { API_ACTIONS, API_ACTION_TYPES, STORAGE_KEYS } from "@Utils/constants";
import { getUserAccessToken } from "@Utils/user";
import { useEffect, useState } from "react";
import { logError } from "repoV2/utils/common/error/error";
import { getLocalStorageItem } from "repoV2/utils/common/storage/getterAndSetters";

export interface IVenueDetails {
    address_type: number;
    venue_details: {
        joining_link: string;
        zoom_meeting_id: number;
    };
}

export const fetchVenueDetails = ({ listingUuid }: { listingUuid: string }) => {
    const apiInfo = API_ACTIONS[API_ACTION_TYPES.GET_VENUE_DETAILS];
    const jwtToken = getUserAccessToken();

    return ApiCall({
        url: apiInfo.url({ listingUuid }),
        method: apiInfo.method,
        headers: { "jwt-token": jwtToken },
    }).then((apiResponse: any) => {
        const { status, data, message } = apiResponse?.data || {};
        if (status !== 200) throw new Error(message);
        return data;
    });
};

const useVenueDetails = ({ listingUuid }: { listingUuid?: string | null }) => {
    const [loading, setLoading] = useState(false);
    const [venueDetails, setVenueDetails] = useState<IVenueDetails>();

    useEffect(() => {
        const accessToken = getUserAccessToken();
        const refreshToken = getLocalStorageItem(STORAGE_KEYS.REFRESH_TOKEN);
        const isLoggedIn = accessToken && refreshToken;

        if (listingUuid && isLoggedIn) {
            setLoading(true);
            fetchVenueDetails({ listingUuid })
                .then(data => {
                    setVenueDetails(data);
                })
                .catch(error =>
                    logError({
                        error,
                        severity: "critical",
                        when: "fetchVenueDetails",
                        occuredAt: "useVenueDetails",
                    })
                )
                .finally(() => setLoading(false));
        }
    }, [listingUuid]);

    return {
        loading,
        venueDetails,
    };
};

export default useVenueDetails;
