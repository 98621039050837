import React from "react";
import ExpandMore from "@mui/icons-material/ExpandMore";
import InputAdornment from "@mui/material/InputAdornment";
import ExlySearchableDropdown from "repoV2/features/Common/modules/Form/modules/ExlySearchableDropdown/ExlySearchableDropdown";
import getCountryCodeOptionsList from "repoV2/features/CheckoutPages2/utils/getCountryCodeOptionsList";
import { getFlagEmoji } from "repoV2/features/Common/modules/Location/utils/getFlagEmoji";
import { IExlySearchableDropdown } from "repoV2/features/Common/modules/Form/modules/ExlySearchableDropdown/ExlySearchableDropdown.interfaces";
import { classNames } from "repoV2/utils/common/render/classNames";
import styles from "./CountryCodeAdornment.module.scss";

const { countries_flag_data_options, countries_flag_data } =
    getCountryCodeOptionsList();

const CountryCodeAdornment = (
    props: Pick<IExlySearchableDropdown, "onChange" | "name" | "classes">
) => {
    const { classes, name, onChange } = props;

    return (
        <InputAdornment
            position="start"
            className={classNames(styles.countryCodeWrapper, classes?.root)}
        >
            <ExlySearchableDropdown
                clearOnFocus
                name={name}
                options={countries_flag_data_options}
                classes={{
                    textInput: styles.TextInput,
                    hasPopupIcon: styles.countryCodeSelectIcon,
                    inputRoot: styles.countryCodeSelect,
                    listbox: styles.countryCodeSelectMenu,
                    popper: styles.countryCodePopper,
                }}
                /**
                 * @dev
                 * Need to Remove the selected country flag
                 * when the search text input is active so that
                 * previous selected country during search is not
                 * visible.
                 */
                CustomPrefixComponent={({
                    option: selectedOption,
                    searchActive,
                }) => (
                    <span className={styles.countryCodeFlag}>
                        {!searchActive &&
                            selectedOption &&
                            getFlagEmoji(
                                countries_flag_data?.[selectedOption.value].id
                            )}
                    </span>
                )}
                menuPlacement="bottom-start"
                inputVariant="standard"
                onChange={onChange}
                ExpandIcon={<ExpandMore fontSize="small" />}
            />
        </InputAdornment>
    );
};

export default CountryCodeAdornment;
