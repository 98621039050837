import { getFlagEmoji } from "repoV2/features/Common/modules/Location/utils/getFlagEmoji";
import { COUNTRIES_DATA_LIST } from "repoV2/utils/common/location/countriesData";

interface IGetCountryCodeOptions {
    id: string;
    value: string;
    countryName: string;
    selectedLabel: string;
    label: string;
}

/**
 * @description This is used to get the country code options
 * @param arg => { CountryLabelJsx }
 * CountryLabelJsx => label JSX for country codes, defaulValue CountryCodeLabel
 * @returns
 */
export default function getCountryCodeOptionsList() {
    const countries_flag_data: { [key: string]: IGetCountryCodeOptions } = {};
    const countries_flag_data_options: Array<IGetCountryCodeOptions> = [];
    COUNTRIES_DATA_LIST.forEach(country => {
        const option = {
            id: country.code,
            value: country.dial_code,
            countryName: country.name,
            selectedLabel: `${country.dial_code}`,
            label: `${getFlagEmoji(country.code)} ${country.name} \xa0 ${
                country.dial_code
            }`,
        };
        if (country.dial_code) {
            countries_flag_data_options.push(option);
            countries_flag_data_options.sort((a, b) =>
                a.countryName.localeCompare(b.countryName)
            );
            countries_flag_data[option.value] = option;
        }
    });

    return {
        countries_flag_data,
        countries_flag_data_options,
    };
}
