import React, { useEffect, useState } from "react";
import { NextPage } from "next";
import { useRouter } from "next/router";
import { useDispatch, useSelector } from "react-redux";
import { Button, HTMLHeader, LoadingIndicator } from "@Modules/common";
import { IHTMLHeader } from "@Modules/common/HTMLHeader/HTMLHeader";
import { ILoginPage, IStore, IHost } from "@Interfaces";
import { useIsMounted } from "repoV2/utils/common/render/mount";
import { IFetchDataAction } from "@Redux/sagas/api";
import { DEFAULT_PROFILE_PIC_SRC } from "repoV2/utils/common/assets/assets";
import {
    ACTION_TYPES,
    API_ACTION_TYPES,
    createAction,
    useCanRender,
    STORAGE_KEYS,
    ROUTES,
    isBrowser,
    BUTTON_DESIGNS,
    isEmpty,
    digitsRegex,
    getLoginNextUrl,
    googleIcon,
    QUERY_PARAMS,
    getUserAccessToken,
    loginUser,
    INPUT_TYPES,
    getQueryParam,
    ADDRESS_TYPE,
    LOGIN_TYPE,
} from "@Utils";
import { ButtonV2 } from "@Modules/common/ButtonV2";
import {
    setLocalStorageItem,
    removeLocalStorageItem,
    setSessionStorageItem,
} from "repoV2/utils/common/storage/getterAndSetters";
import { appendQueryParams, getExternalUrl } from "@Utils/urls";
import { TextInput } from "@Modules/common/Form";
import useVenueDetails from "@Utils/common/useVenueDetails";
import { EMAIL_REGEX } from "repoV2/utils/common/validators/regex.constants";
import { getEmailHasValidTopLevelDomain } from "repoV2/utils/common/validators/validators";
import {
    LOADING_INDICATOR_DESIGNS,
    LOADING_INDICATOR_PROGRESS_TYPES,
} from "repoV2/components/common/LoadingIndicators/LoadingIndicator.data";
import EmailIcon from "@mui/icons-material/EmailRounded";
import { classNames } from "repoV2/utils/common/render/classNames";
import { PhoneInputField } from "repoV2/features/Authentication/modules/Login/modules/PhoneInputField/PhoneInputField";
import Form from "repoV2/components/common/Form/Form/Form";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import {
    LOGIN_FORM_KEYS,
    PHONE_NUMBER_LENGTH,
} from "repoV2/features/Authentication/Authentication.constants";
import { IPhoneInputField } from "repoV2/features/Authentication/modules/Login/modules/PhoneInputField/PhoneInputField.interfaces";
import { PLUS_AND_SPACES } from "repoV2/features/Common/constants/regex";
import { useLogin } from "repoV2/features/Authentication/modules/Login/utils/useLogin";
import style from "./login.module.scss";

const Login: NextPage<ILoginPage.IProps, ILoginPage.InitialProps> = ({
    hostName,
    nextURL,
    gLoginError,
    gAccessToken,
    gRefreshToken,
    gCustomerUuid,
    gCustomerName,
    gCustomerEmail,
    gLoginErrorMessage,
}: ILoginPage.IProps) => {
    const paramsListingId = getQueryParam("listing_uid");
    const { loading: isVenueDetailsLoading, venueDetails } = useVenueDetails({
        listingUuid: paramsListingId,
    });

    const host: IStore.IState["host"] = useSelector(
        (state: IStore.IState) => state.host
    );
    const hostData: IHost.IHostData = host?.dataHost
        ?.profile_data as IHost.IHostData;

    const { defaultDialCode } = useLogin();

    const [userInput, setUserInput] = useState<{
        email?: string;
        phone_number?: string;
        country_code?: string;
    }>({ country_code: defaultDialCode });
    const [inputType, setInputType] = useState<"phone_number" | "email">(
        LOGIN_FORM_KEYS.email
    );
    const [inputError, setInputError] = useState<string>("");
    const [loginError, setLoginError] = useState<boolean>(false);
    const [showCustomError, setShowCustomError] = useState<boolean>(false);
    const [processing, setProcessing] = useState<boolean>(false);
    const [progress, setProgress] = useState(0);
    const isLoginWithEmail = inputType === LOGIN_FORM_KEYS.email;
    const isLoginWithPhone = inputType === LOGIN_FORM_KEYS.phone_number;

    const dispatch = useDispatch();
    const canRender = useCanRender(isBrowser() ? !getUserAccessToken() : false);
    const isMounted = useIsMounted();
    const router = useRouter();

    const redirectToNextUrl = () => {
        router.push(getLoginNextUrl(nextURL));
    };

    useEffect(() => {
        setSessionStorageItem(
            STORAGE_KEYS.LOGIN_TYPE,
            LOGIN_TYPE[STORAGE_KEYS.EMAIL]
        );

        // TODO: find out why this is being cleared on login
        removeLocalStorageItem(STORAGE_KEYS.COUNTRY_CODE);
        removeLocalStorageItem(STORAGE_KEYS.PHONE_NUMBER);

        const accessToken = getUserAccessToken();
        if (accessToken && isBrowser()) {
            loginUser();
            redirectToNextUrl();
            return;
        }

        // for google login
        if (gAccessToken && gRefreshToken) {
            loginUser({
                customerUuid: gCustomerUuid,
                customerEmail: gCustomerEmail,
                customerName: gCustomerName,
                accessToken: window.atob(gAccessToken || ""),
                refreshToken: window.atob(gRefreshToken || ""),
            });
            redirectToNextUrl();
            return;
        }

        if (gLoginError === "true") {
            setLoginError(true);
            setShowCustomError(true);
            setInputError(gLoginErrorMessage || "");
        }
    }, []);

    useEffect(() => {
        // redirect user to zoom link is listing id present in url
        if (paramsListingId && !isVenueDetailsLoading && venueDetails) {
            const {
                address_type,
                venue_details: { joining_link },
            } = venueDetails;
            if (address_type === ADDRESS_TYPE.ZOOM_AUTO_GENERATE)
                window.location.href = joining_link;
        }
    }, [isVenueDetailsLoading]);

    const handleEmailError = (value: string) => {
        const disableCheckEmailTopLevelDomain =
            hostData?.disable_booking_email_whitelisting || false;
        if (isEmpty(value)) {
            setProgress(0);
            setInputError("Required");
        } else if (
            !EMAIL_REGEX.test(value) ||
            (!disableCheckEmailTopLevelDomain &&
                !getEmailHasValidTopLevelDomain(value))
        ) {
            setProgress(33);
            setInputError("Invalid Email ID");
        } else {
            setProgress(33);
            setInputError("");
        }
    };
    const handlePhoneError = (value?: string) => {
        if (isEmpty(value)) {
            setProgress(0);
            setInputError("Required");
        } else if (
            (value && !digitsRegex.test(value)) ||
            value?.length !== PHONE_NUMBER_LENGTH
        ) {
            setProgress(33);
            setInputError("Invalid Phone Number");
        } else {
            setProgress(33);
            setInputError("");
        }
    };
    const onEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setLoginError(false);
        setShowCustomError(false);
        const value = e?.target?.value?.trim();
        setUserInput({ email: value });
        setProcessing(false);
        handleEmailError(value);
        dispatch(createAction(ACTION_TYPES.USER.RESET_EMAIL));
    };

    useEffect(() => {
        dispatch(
            createAction(ACTION_TYPES.UTILS.POST_ANALYTICS, {
                screen: "mypageScreen",
                funnel: "mypage",
                flow: "mypage",
                event_name: "completelyLoaded",
                action: "view",
            })
        );
    }, []);

    const inputErrorCallback = (
        errorResponse: IFetchDataAction.IReturnPayload
    ) => {
        const { message, status } = errorResponse.response;
        let errorMessage = "";
        // in case of 400 status, show backend error message
        if (status === 400) {
            errorMessage = message;
            setShowCustomError(true);
        } else if (inputType === INPUT_TYPES.EMAIL) {
            errorMessage =
                "Kindly verify the email with the one used while booking.";
        } else {
            errorMessage =
                "Kindly verify the phone number with the one used while booking.";
        }
        setProcessing(false);
        setProgress(33); // top progress bar, in case of error showing that 33% of flow of login is done
        setLoginError(true);
        setInputError(errorMessage);
    };

    const onSendOTPClick = async (e: React.FormEvent) => {
        // not adding e.preventDefault(); causes XHR to get cancelled in iOS
        // https://stackoverflow.com/questions/7577275/jquery-ajax-requests-are-getting-cancelled-without-being-sent
        e.preventDefault();

        if (inputError) return;
        setProcessing(true);
        setProgress(66);

        const { country_code, phone_number: phoneNumber, email } = userInput;
        const countryCode = country_code?.replaceAll(" ", "");

        const payload = {
            [LOGIN_FORM_KEYS.email]: email,
            [LOGIN_FORM_KEYS.phone_number]: phoneNumber,
            [LOGIN_FORM_KEYS.country_code]: countryCode?.replace(
                PLUS_AND_SPACES,
                ""
            ),
            sub_domain: hostName,
        };

        dispatch(
            createAction(ACTION_TYPES.UTILS.API_CALL, {
                apiActionType: API_ACTION_TYPES.VERIFY_BOOKING_EXISTENCE,
                queryParams: payload,
                successCallback: ({ response }) => {
                    if (response?.data?.is_creator) {
                        // Store the flag that the login is that of a creator
                        setLocalStorageItem(STORAGE_KEYS.IS_CREATOR, true);
                    } else {
                        // Clears out the is_creator flag just to ensure no confusion after login
                        removeLocalStorageItem(STORAGE_KEYS.IS_CREATOR);
                    }
                    setProgress(100);
                    setLocalStorageItem(
                        STORAGE_KEYS.EMAIL,
                        response?.data?.email
                    );
                    let otpPayload = null;
                    // if end user has entered phone number for otp
                    if (inputType === "phone_number") {
                        setLocalStorageItem(
                            STORAGE_KEYS.PHONE_NUMBER,
                            phoneNumber
                        );

                        setLocalStorageItem(
                            STORAGE_KEYS.COUNTRY_CODE,
                            countryCode
                        );
                        otpPayload = {
                            phone: phoneNumber,
                            country_code: countryCode,
                            sub_domain: hostName,
                        };
                    } else {
                        otpPayload = {
                            email,
                            sub_domain: hostName,
                        };
                    }
                    dispatch(
                        createAction(ACTION_TYPES.UTILS.API_CALL, {
                            apiActionType: API_ACTION_TYPES.GENERATE_OTP,
                            payload: otpPayload,
                            successCallback: () => {
                                // TODO: Implement client side routing
                                // Router.push(
                                //     `/verifyOTP/${window.location.search}`
                                // );
                                setProcessing(false);
                                setProgress(0);
                                window.location.href = `${ROUTES.VERIFYOTP.route}/${window.location.search}`;
                            },
                            errorCallback: inputErrorCallback,
                        })
                    );
                },
                errorCallback: inputErrorCallback,
            })
        );
    };

    // to redirect to google login specific page
    const handleGoogleLogin = () => {
        if (isBrowser()) {
            window.location.href = appendQueryParams(
                getExternalUrl(
                    `//${process.env.NEXT_PUBLIC_LOGIN_SUBDOMAIN}.${process.env.NEXT_PUBLIC_PROJECT_DOMAIN}${ROUTES.SERVICELOGIN.route}`
                ),
                {
                    [QUERY_PARAMS.SUBDOMAIN]: hostName,
                    [QUERY_PARAMS.NEXT]: nextURL,
                }
            );
        }
    };

    const handleToggleLoginMedium = () => {
        const medium =
            inputType === LOGIN_FORM_KEYS.email
                ? LOGIN_FORM_KEYS.phone_number
                : LOGIN_FORM_KEYS.email;

        handlePhoneError("");
        handleEmailError("");
        setInputError("");
        setUserInput(
            medium === LOGIN_FORM_KEYS.email
                ? {}
                : { country_code: defaultDialCode }
        );
        setInputType(medium);
        setSessionStorageItem(STORAGE_KEYS.LOGIN_TYPE, LOGIN_TYPE[medium]);
    };

    const handlePhoneChange: IPhoneInputField["onChange"] = data => {
        const { phone_number: phoneNumber } = data;
        handlePhoneError(phoneNumber || userInput?.phone_number);
        setUserInput(prev => ({ ...prev, ...data }));
    };

    const htmlHeaderProps: IHTMLHeader.IProps = {
        isEvent: false,
        title: `Login - ${hostData?.title || hostData?.name}'s Content`,
        favicon: hostData?.profile_pic,
        image: hostData?.cover_pic,
        video: hostData?.intro_video,
    };

    if (
        isMounted &&
        canRender &&
        !isVenueDetailsLoading &&
        isEmpty(venueDetails)
    ) {
        return (
            <>
                <HTMLHeader {...htmlHeaderProps} />
                <div className={style.root}>
                    <LoadingIndicator
                        applyTheme
                        progress={progress}
                        progressType={
                            LOADING_INDICATOR_PROGRESS_TYPES.controlled
                        }
                        design={LOADING_INDICATOR_DESIGNS.progress_bar}
                    />
                    <div className={style.bodyContainer}>
                        <div className={style.boxContainer}>
                            <div className={style.innerbox}>
                                <div className={style.flexRow}>
                                    <img
                                        className={style.image}
                                        src={
                                            hostData?.profile_pic ||
                                            DEFAULT_PROFILE_PIC_SRC
                                        }
                                        loading="lazy"
                                        alt="profile"
                                    />
                                    {loginError ? (
                                        <div className={style.errorMsg}>
                                            <span>Oops!&nbsp;</span>
                                            {showCustomError
                                                ? inputError
                                                : "No Bookings Found..."}
                                        </div>
                                    ) : null}
                                </div>
                                <div className={style.loginDescription}>
                                    Log in using your registered email or phone
                                    number which you used while booking. You
                                    will receive an OTP / Verification Code for
                                    verification.
                                </div>
                            </div>
                            <Form
                                defaultValues={{
                                    country_code: defaultDialCode,
                                }}
                                onSubmit={() => {}}
                            >
                                <div className={style.innerbox}>
                                    <div className={style.inputLabel}>
                                        Login
                                    </div>

                                    {isLoginWithEmail && (
                                        <TextInput
                                            name="userInput"
                                            type="text"
                                            value={userInput.email}
                                            onChange={onEmailChange}
                                            className={style.inputField}
                                            error={!isEmpty(inputError)}
                                            placeholder="Enter your registered email"
                                        />
                                    )}

                                    {isLoginWithPhone && (
                                        <PhoneInputField
                                            name={LOGIN_FORM_KEYS.country_code}
                                            defaultDialCode={defaultDialCode}
                                            error={!isEmpty(inputError)}
                                            onChange={handlePhoneChange}
                                        />
                                    )}

                                    {inputError && (
                                        <div className={style.inputError}>
                                            {inputError}
                                        </div>
                                    )}

                                    <ButtonV2
                                        applyTheme
                                        design="custom"
                                        className={`${style.submitButton} mb-3`}
                                        onClick={onSendOTPClick}
                                        disabled={
                                            isEmpty(userInput) ||
                                            !isEmpty(inputError) ||
                                            processing
                                        }
                                    >
                                        {!processing
                                            ? "Send OTP / Verification Code"
                                            : "Processing..."}
                                    </ButtonV2>
                                    <div className={style.googleLoginLabel}>
                                        <div className={style.hrLines}>
                                            Or Login With
                                        </div>
                                    </div>
                                    <Button
                                        design={BUTTON_DESIGNS.PLAIN_TEXT}
                                        buttonType="button"
                                        content={
                                            <div className={style.btnIcon}>
                                                <img
                                                    src={googleIcon}
                                                    alt="Google"
                                                    className={style.googleIcon}
                                                />
                                                <span>Sign in with Google</span>
                                            </div>
                                        }
                                        className={style.googleBtn}
                                        onClick={handleGoogleLogin}
                                    />
                                    <Button
                                        design={BUTTON_DESIGNS.PLAIN_TEXT}
                                        buttonType="button"
                                        content={
                                            <div className={style.btnIcon}>
                                                {isLoginWithEmail ? (
                                                    <PhoneIphoneIcon />
                                                ) : (
                                                    <EmailIcon />
                                                )}
                                                <span>
                                                    Login with{" "}
                                                    {isLoginWithEmail
                                                        ? "Phone"
                                                        : "Email"}
                                                </span>
                                            </div>
                                        }
                                        className={classNames(
                                            style.googleBtn,
                                            style.mediumCta
                                        )}
                                        onClick={handleToggleLoginMedium}
                                    />
                                </div>
                                <div className={style.disclaimer}>
                                    <div className={style.innerbox}>
                                        By signing in, you agree to our&nbsp;
                                        <a
                                            href={
                                                hostData?.custom_t_c ||
                                                "/TermsAndConditions"
                                            }
                                            className={style.link}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            Terms
                                        </a>
                                        &nbsp;and&nbsp;
                                        <a
                                            href="/PrivacyPolicy"
                                            className={style.link}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            Privacy policy
                                        </a>
                                        .
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    return (
        <>
            <HTMLHeader {...htmlHeaderProps} />
            <div className={style.root}>
                <div className={style.bodyContainer}>
                    <LoadingIndicator
                        applyTheme
                        design={LOADING_INDICATOR_DESIGNS.rotating_arcs}
                    />
                </div>
            </div>
        </>
    );
};

Login.getInitialProps = async (
    ctx: ILoginPage.IPageContext
): Promise<ILoginPage.InitialProps> => {
    const { hostName } = ctx.params;
    const nextURL = ctx.query[QUERY_PARAMS.NEXT] as string;
    const gAccessToken = ctx.query[QUERY_PARAMS.ACCESS_TOKEN] as string;
    const gRefreshToken = ctx.query[QUERY_PARAMS.REFRESH_TOKEN] as string;
    const gCustomerUuid = ctx.query[QUERY_PARAMS.CUSTOMER_UUID] as string;
    const gCustomerName = ctx.query[QUERY_PARAMS.CUSTOMER_NAME] as string;
    const gCustomerEmail = ctx.query[QUERY_PARAMS.CUSTOMER_EMAIL] as string;
    const gLoginError = ctx.query[QUERY_PARAMS.GOOGLE_LOGIN_ERROR] as string;
    const gLoginErrorMessage = ctx.query[
        QUERY_PARAMS.GOOGLE_LOGIN_ERROR_MESSAGE
    ] as string;

    // These dispatches are not async thunks but synchronous redux
    //  actions, so no need to launch them all in parallel
    ctx.store.dispatch(
        createAction(ACTION_TYPES.UTILS.API_CALL, {
            apiActionType: API_ACTION_TYPES.FETCH_HOST,
            urlArgs: { hostName },
        })
    );
    ctx.store.dispatch(
        createAction(ACTION_TYPES.UTILS.API_CALL, {
            apiActionType: API_ACTION_TYPES.FETCH_HOST_THEME,
            urlArgs: { hostName },
        })
    );

    return {
        hostName,
        nextURL,
        gLoginError,
        gLoginErrorMessage,
        gAccessToken,
        gRefreshToken,
        gCustomerUuid,
        gCustomerName,
        gCustomerEmail,
    };
};

export default Login;
