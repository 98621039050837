import React from "react";
import FormHelperText from "@mui/material/FormHelperText";
import { IErrorMessageProps } from "./ErrorMessage.interfaces";

const ErrorMessage = ({
    classes,
    showError,
    errorMessage,
    CustomErrorMessageComponent,
}: IErrorMessageProps) =>
    showError ? (
        <FormHelperText className={classes?.helperText}>
            {CustomErrorMessageComponent ? (
                <CustomErrorMessageComponent errorMessage={errorMessage} />
            ) : (
                errorMessage
            )}
        </FormHelperText>
    ) : null;

export default ErrorMessage;
