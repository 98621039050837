import React, { FC, useEffect } from "react";
import CountryCodeAdornment from "repoV2/features/CheckoutPages2/modules/PaymentDetailsSection/modules/FormGroups/modules/UserDetailsFormGroup/modules/CountryCodeAdornment/CountryCodeAdornment";
import { useFormContext } from "react-hook-form";
import { IExlySearchableDropdownOption } from "repoV2/features/Common/modules/Form/modules/ExlySearchableDropdown/ExlySearchableDropdown.interfaces";
import ExlyInput from "repoV2/features/Common/modules/Form/modules/ExlyInput/ExlyInput";
import { LOGIN_FORM_KEYS } from "repoV2/features/Authentication/Authentication.constants";
import { IPhoneInputField } from "./PhoneInputField.interfaces";
import styles from "./PhoneInputField.module.css";

export const PhoneInputField: FC<IPhoneInputField> = ({
    defaultDialCode,
    name,
    error,
    onChange,
}) => {
    const { setValue } = useFormContext();

    const handleCountryCodeChange = (option: IExlySearchableDropdownOption) => {
        setValue(LOGIN_FORM_KEYS.country_code, option.value);
        if (onChange)
            onChange({ [LOGIN_FORM_KEYS.country_code]: option.value });
    };

    const handlePhoneNumberChange = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        if (onChange)
            onChange({ [LOGIN_FORM_KEYS.phone_number]: e.target.value });
    };

    useEffect(() => {
        const handleResetCountryCode = () => {
            setValue(LOGIN_FORM_KEYS.country_code, defaultDialCode);
        };

        return () => handleResetCountryCode();
    }, []);

    return (
        <div className={styles.root}>
            <ExlyInput
                name={name}
                InputProps={{
                    startAdornment: (
                        <CountryCodeAdornment
                            name={LOGIN_FORM_KEYS.country_code}
                            onChange={handleCountryCodeChange}
                            classes={{ root: styles.startAdornment }}
                        />
                    ),
                    classes: { root: styles.input, input: styles.inputRoot },
                }}
                classes={{
                    helperText: styles.helperText,
                }}
                placeholder="Enter your registered phone number"
                variant="outlined"
                onChange={handlePhoneNumberChange}
                error={error}
                fullWidth
            />
        </div>
    );
};
