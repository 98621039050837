/**
 * @description flag Emoji alt code generator from country code
 * @param countryCode countryCode value in string eg. "IN", "in" case insensitive
 * @returns alt code string for flag emoji corresponding to country code.
 * @refer for more information
 * [https://dev.to/jorik/country-code-to-flag-emoji-a21]
 * @example
 *
 * countryCode => "IN" or "in"
 * 1. turn to uppercase => IN
 * 2. replace each character with their emoji alt codes for Regional Indicator Letters
 *
 * Regional Indicator Letters A alt code => 🇦 => &#127462
 * and string combination of two Regional Indicator Symbol results in regional flag.
 * eg. indian flag = '🇮'+'🇳' = '🇮🇳'
 *
 * base value = 127462 - U(A) = 127462 - 65 = 127397
 * U(A) = UTF16 position of A = 65
 * U(I) = UTF16 position of I = 73
 * U(N) = UTF16 position of N = 78
 *
 * '🇮' = base value + U(I)
 * '🇳' = base value + U(N)
 *
 * combine above both values in `string` format and get the `regional flag`
 */
export const getFlagEmoji = (countryCode: string) =>
    String(countryCode)
        .toUpperCase()
        .replace(/./g, char =>
            String.fromCodePoint(127397 + char.charCodeAt(0))
        );
