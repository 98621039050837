import TextField from "@mui/material/TextField";
import React from "react";
import { useFormContext } from "react-hook-form";
import { IExlyInput } from "./ExlyInput.interfaces";

/**
 * @dev
 * Input component designed to be used within the react-hook-form smart form component
 * (src/components/common/Form/Form/Form.tsx). This input field is automatically registered
 * in the react-hook-form lifecycle.
 * @caution
 * we are not using FormHelperText because TextField Mui Component already implements it by default
 */

const ExlyInput = (props: IExlyInput) => {
    const {
        name,
        placeholder,
        fullWidth,
        InputProps,
        classes = {},
        className,
        registerOptions,
        helperText,
        multiline,
        rows,
        autoComplete,
        onBlur,
        onChange,
        disabled,
        overrideInputProps,
        error: errorProp,
    } = props;
    const {
        register,
        formState: { errors },
    } = useFormContext();
    const error = name ? errors[name] : null;
    const showError = !!error;
    const { message: errorMessage } = error || {};

    return (
        <TextField
            error={showError || errorProp}
            helperText={helperText || errorMessage}
            classes={{ root: classes.root }}
            FormHelperTextProps={{ className: classes.helperText }}
            placeholder={placeholder}
            fullWidth={fullWidth}
            multiline={multiline}
            rows={rows}
            InputProps={InputProps}
            autoComplete={autoComplete}
            className={className}
            disabled={disabled}
            {...(name
                ? register(name, { ...registerOptions, onChange, onBlur })
                : { onChange, onBlur })}
            {...overrideInputProps}
        />
        // We are not using `FormHelperText` (like in other form components) because
        // MUI `TextField` already implements it by default.
    );
};

export default ExlyInput;
