import {
    getCountryDataBasedOnTimezone,
    getUserTimezone,
} from "repoV2/features/Common/modules/Timezone/Timezone.utils";
import { INDIA_COUNTRY_DATA } from "repoV2/utils/common/location/countriesData";

export const useLogin = () => {
    const timezone = getUserTimezone();
    const { dial_code: defaultDialCode = INDIA_COUNTRY_DATA.dial_code } =
        getCountryDataBasedOnTimezone({ timezone }) || {};

    return { defaultDialCode };
};
