import React from "react";
import { FieldValues, FormProvider, useForm } from "react-hook-form";
import { IForm } from "./IForm";

/**
 * Smart Form Component using react-hook-form.
 * This idea here is that you can easily compose your form with inputs.
 * This Form component can automatically collect form data from enclosed input components.
 * please visit this link for inception and usage: https://react-hook-form.com/advanced-usage#SmartFormComponent
 *
 * Note: All enclosed input components must be registered in form to be tracked by react-hook-form lifecycle.
 *       For example, please check ExlyInput component in src/components/common/Form/FormInputs/ExlyInput/ExlyInput.tsx
 */
const Form = <TFormValues extends FieldValues>({
    children,
    onError,
    onSubmit,
    className,
    autoComplete,
    ...formProps
}: IForm.FormProps<TFormValues>) => {
    const methods = useForm<TFormValues>(formProps);
    const { handleSubmit, setError } = methods;

    return (
        <FormProvider {...methods}>
            <form
                className={className}
                onSubmit={handleSubmit(
                    values => onSubmit({ values, setError }),
                    onError
                )}
                {...(autoComplete && {
                    autoComplete,
                })}
            >
                {children}
            </form>
        </FormProvider>
    );
};

export default Form;
