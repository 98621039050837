import React, { FC } from "react";
import { useFormContext } from "react-hook-form";
import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete";
import Popper, { PopperProps } from "@mui/material/Popper";
import TextField from "@mui/material/TextField";
import { classNames } from "repoV2/utils/common/render/classNames";
import ErrorMessage from "../ErrorMessage/ErrorMessage";
import {
    IExlySearchableDropdown,
    IExlySearchableDropdownOption,
} from "./ExlySearchableDropdown.interfaces";
import styles from "./ExlySearchableDropdown.module.scss";

const PopperComponent: FC<PopperProps> = (props: PopperProps) => {
    return <Popper {...props} />;
};

/**
 * @deprecated Please use ExlyAutocomplete instead of this -
 * repoV2/features/Common/modules/Form/modules/ExlySearchableDropdownV2/ExlySearchableDrowpdownV2.tsx
 *
 * TODO @mayank: make the component un controlled
 * @caveats
 * 1. We have to use the Autocomplete For Dropdown instead of Select
 *    as Select did not have the utility to search/filter the options
 *    based on the user input.
 * 2. search cannot be implemented on JSX with this component. It needs text for that purpose.
 *
 * @working
 * 1. To Use this Searchable Dropdown We have to provide the options whose
 *    structure can be seen in its type
 * 2. renderOption can be used to style the option in the menu list.
 * 3. clearOnFocus boolean can be used to clear the text on focus
 * 4. To register with React hook form, name prop has to be provided
 * 5. Whatever text entered will be compared with the option's `label`
 * 6. Once selected, the input value of search input will be replaced with the `selectedLabel` of option.
 *
 * @caution
 * 1. option selected label and normal label text has to be string for
 *     the autocomplete fuzzy search to be working (limitation on mui's end)
 * 2. CustomPrefixComponent can be used to render the custom JSX as a prefix adornment
 *    to the search input.
 */
const ExlySearchableDropdown: FC<IExlySearchableDropdown> = props => {
    const {
        name,
        options,
        placeholder,
        classes,
        ExpandIcon,
        registerOptions = {},
        inputVariant = "outlined",
        menuPlacement,
        clearOnFocus,
        CustomPrefixComponent,
        CustomErrorMessageComponent,
        renderOption,
        value: selectionValue,
        onChange,
    } = props;

    const [searchActive, setSearchActive] = React.useState(false);
    const selectedOptionRef = React.useRef<IExlySearchableDropdownOption>();

    if (selectionValue?.value) {
        selectedOptionRef.current = selectionValue;
    }
    const [inputTextValue, setInputTextValue] = React.useState<
        string | undefined
    >(selectionValue?.selectedLabel || undefined);

    const {
        register,
        getValues,
        setValue,
        formState: { errors, defaultValues },
    } = useFormContext();

    const data = getValues();
    const error = name ? errors[name] : null;
    const prefilledValue = name ? data?.[name] : null;
    const defaultValue = name ? defaultValues?.[name] : null;
    const defaultOption = name
        ? options.find(
              ({ value }) => value === prefilledValue || value === defaultValue
          )
        : undefined;
    const showError = !!error;
    const { message: errorMessage } = error || {};

    return (
        <>
            {CustomPrefixComponent &&
                CustomPrefixComponent({
                    option: selectedOptionRef.current || defaultOption,
                    searchActive,
                })}
            <Autocomplete
                multiple={false}
                disableClearable
                autoComplete
                disablePortal
                blurOnSelect
                inputValue={inputTextValue ?? defaultOption?.selectedLabel}
                autoHighlight
                classes={classes}
                fullWidth
                {...(selectionValue && {
                    value: selectionValue,
                })}
                popupIcon={ExpandIcon}
                {...(renderOption && {
                    renderOption: (
                        _props,
                        option: IExlySearchableDropdownOption
                    ) => (
                        <Box component="li" {..._props}>
                            {renderOption(option)}
                        </Box>
                    ),
                })}
                renderInput={params => (
                    <TextField
                        {...params}
                        error={showError}
                        variant={inputVariant}
                        placeholder={placeholder}
                        className={classes?.textInput}
                        onFocus={() => {
                            setSearchActive(true);
                        }}
                        onBlur={() => {
                            setSearchActive(false);
                        }}
                        onChange={event => {
                            setInputTextValue(event.target.value);
                        }}
                    />
                )}
                PopperComponent={popperProps => (
                    <PopperComponent
                        {...popperProps}
                        className={classNames(
                            classes?.popper,
                            styles.popperBase
                        )}
                        placement={menuPlacement}
                    />
                )}
                {...(name ? register(name, registerOptions) : {})}
                options={options}
                onChange={(_event, option) => {
                    // run setValue before onChange of the calling component.
                    // so that if the calling component uses getValues inside it,
                    // the latest value is reflected in the code
                    if (name)
                        setValue(name, option.value, { shouldValidate: true });
                    if (onChange) onChange(option);
                    setInputTextValue(option.selectedLabel);
                    selectedOptionRef.current = option;
                }}
                onOpen={() => {
                    if (clearOnFocus) setInputTextValue("");
                }}
                onClose={() => {
                    setInputTextValue(
                        selectedOptionRef?.current?.selectedLabel ||
                            defaultOption?.selectedLabel
                    );
                }}
            />
            <ErrorMessage
                showError={showError}
                errorMessage={errorMessage}
                classes={classes}
                CustomErrorMessageComponent={CustomErrorMessageComponent}
            />
        </>
    );
};

export default ExlySearchableDropdown;
